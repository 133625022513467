import { combineReducers } from 'redux'
import flexibleReducer from './flexibleReducer'
import menuReducer from './menuReducer'
import themeOptionReducer from './themeOptionReducer'
import heroReducer from './heroReducer'
import previewReducer from './previewReducer'
import headerReducer from './headerReducer'
import searchReducer from './searchReducer'
import metaDataReducer from './metaDataReducer'
import configReducer from './configReducer'

export default combineReducers({
  flexibleReducer,
  menuReducer,
  themeOptionReducer,
  heroReducer,
  previewReducer,
  headerReducer,
  searchReducer,
  metaDataReducer, 
  configReducer
})
