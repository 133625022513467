const menu = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_MENU':
      return Object.assign({}, state, { aa: action.payload })
    case 'UPDATE_FOOTER_1':
      return Object.assign({}, state, { footer_1: action.payload })
    case 'UPDATE_FOOTER_2':
      return Object.assign({}, state, { footer_2: action.payload })
    case 'UPDATE_FOOTER_3':
      return Object.assign({}, state, { footer_3: action.payload })
    case 'UPDATE_MENUS':
      return Object.assign({}, state, { header: action.payload })
    default:
      return state
  }
}

export default menu
