import App, { Container } from 'next/app'
import React from 'react'
import { createStore } from 'redux'
import reducer from './../src/reducers'
import withRedux from 'next-redux-wrapper'
import { Provider } from 'react-redux'
import { get } from './../src/fetchController'
import Cookies from 'universal-cookie'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import ConfigLayout from './../layouts/ConfigLayout'
library.add(fal, fab)

const makeStore = (initialState, options) => {
  return createStore(reducer, initialState)
}

class MyApp extends App {


  render() {
    const { Component, pageProps, store } = this.props
    return (
      <Container>
        <Provider store={store}>
          <ConfigLayout>
          <Component {...pageProps} />
          </ConfigLayout>
        </Provider>
      </Container>
    )
  }
}

export default withRedux(makeStore)(MyApp)
