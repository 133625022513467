const metadata = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_METADATA':
      console.log("updating metadata,", action.payload)
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export default metadata
