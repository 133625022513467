const search = (state = { response: [], token:'',numResults: '0', hasMore:false, page:0, loading:false,query:'' }, action) => {
    switch (action.type) {
      case 'UPDATE_SEARCH':
        let newstate = Object.assign({},state, { response: action.payload })
        return newstate
      case "SET_SEARCH_TOKEN":
        return Object.assign({}, state, { token: action.payload })
        break;
      case "HAS_MORE":
        return Object.assign({}, state, { hasMore: action.payload })
      break;
      case "SET_SEARCH_RESULTS":
        return Object.assign({}, state, {numResults: action.payload})
      case "SET_SEARCH_PAGE":
        return Object.assign({}, state, {page: action.payload})
      case "SET_LOADING":
        return Object.assign({}, state, {loading: action.payload})
      case "SET_QUERY":
        return Object.assign({}, state, {query: action.payload})
      case "ADD_PAGE":
        var newResponse;
        if(action.payload === null || action.payload.length === 0){
            newResponse = state.response
        } else {
          newResponse = state.response.concat(action.payload);            
        }
        return Object.assign({}, state, {response: newResponse})
  
      default:
        return {
          ...state
        }
    }
  }
  
  export default search
  