const config = (state = {authConfig:{download:false}}, action) => {
    switch (action.type) {
      case 'UPDATE_CONFIG':
      let newstate= Object.assign({}, state, action.payload)
      return newstate
        
      default:
        return state
    }
  }
  
  export default config
  