const hero = (state = {heroIndex:0}, action) => {
  switch (action.type) {
    case 'UPDATE_HERO':
    
      let newstate = Object.assign({}, {heroIndex: action.payload});
      return newstate
    default:
      return {
        ...state
      }
  }
}

export default hero
