import Header from '../components/Header'
import Footer from '../components/Footer'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from './../src/fetchController'
import {updateConfig } from './../src/actions/configAction'
import {getProfile} from './../authentication/auth0'

class Layout extends Component {
  
    state={
        profile:{},
        config:{}
    }
    componentDidMount(){
        getProfile().then((res) => {
            this.setState({profile:res});
            get("/wp-json/amfrest/v1/authconfig").then((authConfig) =>{
                this.props.setConfig({profile:res, authConfig:authConfig})
            })
        }).catch((err) => {})
    }


    render() {
        return (
            <div>
                {this.props.children}
            </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    configReducer: state.configReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setConfig: data => {
        dispatch(updateConfig(data))
      }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
