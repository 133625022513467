const initialState = {
  hasHero: false
}

const header = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case 'UPDATE_HEADER':
      return { ...state, hasHero: payload }
    default:
      return state
  }
}

export default header
