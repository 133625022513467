const preview = (state = { isPreview: true }, action) => {
  switch (action.type) {
    case 'UPDATE_PREVIEW':
      let newstate = Object.assign({}, { isPreview: action.payload })
      return newstate
    default:
      return {
        ...state
      }
  }
}

export default preview
